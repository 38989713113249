.loading {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.home {
}

header {
  background-size: cover;
  background-position: 50% 20%;
  background-repeat: no-repeat;
  background-size: contain;
}

header nav {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: auto;
  padding-left: 50px;
  letter-spacing: 1px;
  font-weight: 700;
}

header nav a {
  color: gray !important;
  text-decoration: none;
}

a:visited,
a:active,
a:focus,
a:focus-within {
  color: rgb(255, 255, 255);
}

a:hover {
  color: rgb(46, 24, 24);
}

.fixednav {
  min-width: 100%;
}

.fixednav nav {
  overflow: hidden;
  position: fixed;
  top: 0;
  background-color: #ffffff;
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  letter-spacing: 3px;
  font-weight: 500;
  z-index: 2;
}

.fixed-navbar {
  width: 100%;
  z-index: 1000;
  position: fixed;
  /* height: max-content; */
  top: 0;
  height: 100px;
  /* justify-content: center; */
  background-color: rgba(240, 240, 240, 0.9);
}

.fixed-navbar li {
  padding: 10px;
}

.fixednav nav a {
  margin-left: 40px;
  margin-right: 40px;
  color: gray !important;
}

.logo-bar {
  padding: 30px;
  z-index: 999999;
}

.logo-bar img {
  max-height: 60px;
}

.header-nav-block-none {
  display: none;
}

.logo-bar-background {
  width: 100vw;
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  z-index: -1;
  color: white;
}

.header-products img {
  width: 30vmin;
  object-fit: cover;
  border-radius: 20px !important;
}

.header-products * {
  margin: auto;
}

.header-products {
  width: auto;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: auto;
}

.header-products-product {
  display: inline-block;
  margin: 0px;
  position: relative;
  padding: 5px 5px 0 5px;
  width: 33% !important;
  font-weight: 1000;
}

.header-products-product * {
  font-weight: 1000 !important;
}

.header-products-texts {
  position: absolute;
  color: rgb(255, 255, 255);
  left: 20px;
  top: 20px;
  z-index: 100;
}

.header-products-texts h3 {
  font-weight: 1000;
  font-size: 2vmin;
  padding: 0px;
    margin: 0px;

}

.navbar li {
  display: inline-block;
  margin: 0;
}
.navbar li:after {
  display: block;
  content: "";
  border-bottom: solid 3px gray;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.navbar li:hover:after {
  transform: scaleX(1);
}

.header-products-product img:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.header-products-product img {
  filter: brightness(70%);
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  height: 200px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.header-nav-li-open-menu {
  display: block !important;
}

@media screen and (max-width: 980px) {
  .header-nav li {
    display: none;
  }

  header nav {
    padding-left: 0px;
  }

  .header-nav {
    flex-direction: column;
  }

  .fixed-navbar {
    height: min-content;
  }

  .logo-bar {
    position: absolute;
    left: 0;
    top: 0;
    padding: 11px;
  }
}

.header-nav-backgorunded {
}
.header-nav-hamburger {
  position: absolute;

  top: 5px;
  right: 10px;
}

@media screen and (min-width: 980px) {
  .header-nav-hamburger {
    display: none;
  }
}

@media screen and (max-width: 980px) {
  .header-products-product {
    display: inline-block;
    margin: 0px;
    position: relative;
    width: 50% !important;
  }

  .header-first-el {
    padding-top: 100px !important;
  }

  .header-products-product img {
    height: 170px;
  }
}

.header-nav-li-open-menu {
  display: block !important;
}

.header-products-product-page {
  padding: 20px;
}

.flag-icon {
  margin-right: 5px;
}
